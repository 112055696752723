import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Img from "../../components/Elements/Img"
import SectionContainer from "../../components/SectionContainer"
import VideoPlayer from "../../components/VideoPlayer/index"
import { Container, Row, Col } from "../../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`

const RevomeMarginWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const takeda = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#000000",
        dividerColor: "#000000;",
        invert: {
          backgroundColor: "#AAAAAA",
          dividerColor: "#000000;",
        },
      }}
    >
      <>
        <Seo title="Navigating | Best Managed Markets Campaign" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                  <div style={{ color: "#000000"}}>The Work</div>
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px", color: "#000000" }}>
              <PageTitle>Navigating the future of rare bleeding disorders</PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>
                  <div style={{ color: "#000000"}}>
                    The Situation
                  </div>
                </AccentHeader>                
                <SectionBody size="regular">
                  The hemophilia market is changing quickly. Three times more manufacturers now than 5 years ago. A slew of new products either introduced or in development.<br />
                  <br />
                  As a result, population-based decision makers (payers, C and D suites and hospital systems) are scrambling to evaluate the category. Because one course of treatment for one patient can come with a 7-figure price tag. They want to provide the best care but they also need to have money to care for everyone else.                   
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1 style={{ color: "#000000"}}>How can Takeda maintain its prominent role in the industry and help patients get the medicines they need? </h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noBottomMargin >
          <RevomeMarginWrapper noTopMargin noBottomMargin>
            <VideoPlayer
              poster={() => <Img fluid={data.image3.childImageSharp.fluid} />}
              url="https://player.vimeo.com/video/908316086?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            />
          </RevomeMarginWrapper>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#000000" }}>
            <Row>
              <Col md={4}>
                <SectionBody size="regular">
                <AccentHeader>                  
                  <h2>The Idea</h2>
                </AccentHeader>
                  <div style={{marginTop: "20px"}}>
                    Overseeing care and treatment of populations that includes hemophilia patients is complex. It can be difficult and sometimes even disorienting. How can these decision makers know they’re headed in the right direction.
                  </div>
                </SectionBody>
              </Col> 
              <Col md={4}>
                <SectionBody size="regular">  
                <AccentHeader>                  
                </AccentHeader>              
                  <h1 style={{ color: "#000000"}}>With the most products, the most patients treated, and the longest tenure in the category, Takeda is uniquely equipped and positioned to navigate the future—all while keeping individual patients top of mind.</h1></SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noBottomMargin>
          <Container>
            <RevomeMarginImage>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </RevomeMarginImage>
          </Container>
        </SectionContainer>


        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", color: "#000000" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2>The Execution</h2>
                </AccentHeader>
              </Col>              
            </Row>
            <Row>
              <Col md={8}>
                <SectionBody size="regular">
                  Beautiful old maps with their exquisite details and handcrafting represented today’s hemophilia market. Hand drawn on each of those maps were generational portraits (because these are genetic conditions) that depicted why the right decisions are so important. Because the real bottom line are the patients and the lives they want to live. </SectionBody>
              </Col> 
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>
        
             </>
    </ThemeProvider>
  </Layout>
)

export default takeda

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2024/takeda/image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2024/takeda/image2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image3: file(relativePath: { eq: "2024/takeda/image3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image4: file(relativePath: { eq: "2024/takeda/image4.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image5: file(relativePath: { eq: "2024/takeda/image5.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    videoThumbnail1: file(relativePath: { eq: "2024/xeomin/videoposter.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
